function Check({customStyle}) {
	return(
		<>
			<div className={`p-[5px] rounded-[20px] w-fit h-fit ${customStyle}`}>
				<svg width="16px" height="16px" viewBox="0 0 24 24" fill="none">
					<g id="SVGRepo_bgCarrier" stroke-width="0"/>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
					<g id="SVGRepo_iconCarrier"> <g id="Interface / Check"> <path id="Vector" d="M6 12L10.2426 16.2426L18.727 7.75732" stroke="#082d60" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> </g> </g>
				</svg>
			</div>
		</>
	)
}

export default Check;